import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { ProgramLog } from '../interfaces/log';

const programLogService = (httpClient: AxiosInstance) => {
  return {
    createProgramLog: async (programLog: ProgramLog) => {
      return httpClient
        .post<IAPIResponse<null>>('/program-log', programLog)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Creating program log failed', data: null };
        });
    },

    getProgramLog: async (programId: string, week: number) => {
      return httpClient
        .get<IAPIResponse<null>>(`/program-log/${programId}/${week}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Program log failed', data: null };
        });
    },
  };
};
export default programLogService;
