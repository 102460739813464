import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';

const settingService = (httpClient: AxiosInstance) => {
  return {
    changePassword: async (oldPassword: string, newPassword: string) => {
      const data = { oldPassword, newPassword };
      return httpClient
        .post<IAPIResponse<null>>(`/change-password`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Invalid  password', data: [] };
        });
    },
  };
};

export default settingService;
