import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { IExerciseAssignedResponse } from '../interfaces/exercise-assigned';
import { SeriesWithAssignedExercise } from '../interfaces/series';

const assignedExerciseService = (httpClient: AxiosInstance) => {
  return {
    getAssignedExercise: async (id: string) => {
      return httpClient
        .get<IAPIResponse<IExerciseAssignedResponse>>(`/assigned-exercise/${id}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get exercise', data: null };
        });
    },

    deleteAssignedExercise: async (seriesId: string, exerciseId: string, week: number) => {
      return httpClient
        .delete<IAPIResponse<SeriesWithAssignedExercise>>(`/assigned-exercise/${seriesId}/${exerciseId}/${week}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get exercise', data: null };
        });
    },
  };
};
export default assignedExerciseService;
