/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Root from '../components/Root';

import { apiConfig } from '../config/api-config';
import { IAuth, Role } from '../interfaces/auth';
import { IRootState } from '../interfaces/redux/store';
import { PatientRoutes } from './patient-routes';
import { PractitionerRoutes } from './practitioner';
import { PrivateRoute } from './private-routes';

const Login = lazy(() => import('../pages/login/Login'));
const SignUp = lazy(() => import('../pages/specialist/sign-up/sign-up'));
const Registration = lazy(() => import('../pages/specialist/registration/registration'));
const FourNotFour = lazy(() => import('../pages/FourNotFour'));
const SignUpSuccess = lazy(() => import('../pages/signup-success/SignUpSuccess'));
const SupportLogin = lazy(() => import('../pages/login/SupportLogin'));
const Verification = lazy(() => import('../pages/patient/verification'));

const Routers = () => {
  const auth = useSelector<IRootState, IAuth>(({ auth }) => auth.value);
  const getRole = () => {
    switch (auth.data?.role) {
      case Role.PRACTITIONER:
        return Role.PRACTITIONER;
      case Role.SOLO:
        return Role.SOLO;
      case Role.BUSINESS_ADMIN:
        return Role.BUSINESS_ADMIN;
      case Role.PATIENT:
      default:
        return Role.PATIENT;
    }
  };

  const getRoutes = useCallback(() => {
    const role = getRole();
    if (auth.data && (role === Role.PRACTITIONER || role === Role.SOLO || role === Role.BUSINESS_ADMIN)) {
      return PractitionerRoutes.map((route) => ({ ...route, role: role }));
    }

    if (auth.data && auth.data.role === Role.PATIENT) {
      return PatientRoutes.map((route) => ({ ...route, role: Role.PATIENT }));
    }

    return [];
  }, [auth]);

  return (
    <>
      <Routes>
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Root>
              <PrivateRoute isIndex />
            </Root>
          }
        />

        {getRoutes().map(({ path, component, role }, index) => (
          <Route
            key={index}
            path={`${apiConfig.basePath}${path}`}
            element={
              <Root>
                <PrivateRoute component={component} access={role} />
              </Root>
            }
          />
        ))}

        <Route path={`${apiConfig.basePath}/login`} element={<Login />} />
        <Route path={`${apiConfig.basePath}/support/login/:token`} element={<SupportLogin />} />
        <Route path={`${apiConfig.basePath}/registration/success`} element={<SignUpSuccess />} />
        <Route path={`${apiConfig.basePath}/404`} element={<FourNotFour />} />
        <Route path={`${apiConfig.basePath}/verify`} element={<Verification />} />
        <Route path={`${apiConfig.basePath}/signup/:token`} element={<SignUp />} />
        <Route path={`${apiConfig.basePath}/signup/`} element={<SignUp />} />
        <Route path={`${apiConfig.basePath}/registration/`} element={<Registration />} />


        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;
