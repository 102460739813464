import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { INotificationCount, INotificationRequest, INotificationResponse } from '../interfaces/notifications';

const notificationService = (httpClient: AxiosInstance) => {
  return {
    getNotifications: async () => {
      return httpClient
        .get<IAPIResponse<INotificationResponse>>('/notifications')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read notifications', data: null };
        });
    },

    updateNotification: async (id: string) => {
      return httpClient
        .patch<IAPIResponse<INotificationResponse>>(`/notifications/${id}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update notifications', data: null };
        });
    },

    getNotificationCount: async () => {
      return httpClient
        .get<IAPIResponse<INotificationCount>>('/notifications/count')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read notifications', data: null };
        });
    },

    generateNotification: async (data: INotificationRequest) => {
      return httpClient
        .post<IAPIResponse<null>>(`/notifications/`, data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to create notifications', data: null };
        });
    },
  };
};

export default notificationService;
