import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IExercise } from '../../interfaces/exercise';

const initialState: { value: IExercise[] } = { value: [] };

export const seriesExerciseReducerSlice = createSlice({
  name: 'series-slice-list',
  initialState,
  reducers: {
    addExercise: (state, action: PayloadAction<IExercise>) => {
      const exercises = state.value.filter((exercise) => {
        if (exercise.exerciseId && exercise._id !== action.payload._id) {
          return exercise.exerciseId !== action.payload._id;
        }

        return exercise._id !== action.payload._id;
      });

      state.value = [...exercises, action.payload];
    },
    deleteExercise: (state, action: PayloadAction<string>) => {
      state.value = state.value.filter((exercise) => {
        if (exercise.exerciseId && exercise._id !== action.payload) {
          return exercise.exerciseId !== action.payload;
        }

        return exercise._id !== action.payload;
      });
    },
    resetExercises: (state) => {
      state.value = [];
    },
  },
});

export const { addExercise, deleteExercise, resetExercises } = seriesExerciseReducerSlice.actions;
export default seriesExerciseReducerSlice.reducer;
