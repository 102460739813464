import { IValidation } from './../interfaces/index';
import moment from 'moment';
import { FieldType, FormDataValues, FormValidator } from '../interfaces';
import { validateEmail, validateMobile, validatePassword } from './validation-util';

export const formatDate = (date: string | Date, requiredString = false, requiredTime = false) => {
  const now = moment();

  if (!requiredTime) {
    return moment(date).format('DD/MM/YYYY hh:mm:ss A');
  }

  if (requiredString) {
    const days = now.diff(date, 'days');

    switch (days) {
      case 0:
        return 'Today';

      default:
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }

  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export function clone<T = any>(data: object) {
  return JSON.parse(JSON.stringify(data)) as T;
}

export const createFormData = (data: FormDataValues[]) => {
  const formData = new FormData();
  data.forEach((d) => {
    formData.append(d.name, d.value);
  });

  return formData;
};

export const formValidator = (data: FormValidator): string => {
  if (data.length && data.value.toString().length < data.length) {
    const name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
    const singleDigit = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
    return `Minimum ${singleDigit[data.length]} characters required.`;
  }

  switch (data.type) {
    case FieldType.MOBILE:
      return validateMobile(data.value as string) || '';
    case FieldType.EMAIL:
      return validateEmail(data.value as string) || '';
    case FieldType.PASSWORD:
      return validatePassword(data.value as string) || '';
    case FieldType.NUMBER:
      return isNaN(Number(data.value)) ? (new RegExp('^[0-9]*$').test(data.value.toString()) ? '' : `${data.name} is not a number`) : '';
    default:
      return data.value ? '' : `${data.name} is required`;
  }
};

export const calculateWeeks = (startDate: string | Date, endDate: string | Date) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const diffInDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  const weeks = Math.ceil(diffInDays / 7);
  const weekArray = [];
  for (let i = 1; i <= weeks; i++) {
    weekArray.push(i);
  }
  return weekArray;
};

export const fileDownload = (data: Blob): Promise<boolean> => {
  return new Promise((resolve) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.id = 'file-download-link';
    link.href = url;
    link.setAttribute('download', 'exercise.pdf');
    document.body.appendChild(link);
    link.click();
    // Safe time to trigger click
    setTimeout(() => {
      link.remove();
      resolve(true);
    }, 1000);
  });
};

export const getValidationErrors = (data: IValidation[]) => {
  const err = { email: '', name: '', mobile: '' };
  data.forEach((d) => {
    if (d.field === 'email' && d.message) {
      err.email = d.message;
    }
    if (d.field === 'name' && d.message) {
      err.name = d.message;
    }
    if (d.field === 'mobile' && d.message) {
      err.mobile = d.message;
    }
  });
  return err;
};

export const getExtensionFromFile = (fileName: string): string => {
  const extension = fileName.split('.').pop();
  if (extension && extension === 'jpeg') {
    return 'jpg';
  }

  return extension ?? '';
};

export const formatPrice = (price: number) => {
  if(isNaN(price)) return 'Calculating...';
  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'AUD' }).format(price).replace('A$', '$');
};
