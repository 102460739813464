import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IExercisePreviewState } from '../../interfaces/exercise';
import { programService } from '../../service';
import { clone } from '../../utils/core-utils';

const initialState: IExercisePreviewState = { status: false, message: '', loading: true };

export const patientExerciseAsync = createAsyncThunk<IExercisePreviewState, { programId?: string; seriesId?: string; week?: number }>(
  'patient/series',
  async ({ programId, seriesId, week }): Promise<IExercisePreviewState> => {
    try {
      if (!programId || !seriesId || !week) {
        return initialState;
      }

      const res = await programService.getSeriesBySeriesIdAndWeek(programId, seriesId, week);
      return res.data ? res : initialState;
    } catch (err: any) {
      return { status: false, message: err.message as string };
    }
  },
);

export const patientExerciseSlice = createSlice({
  name: 'patient-series',
  initialState: {
    value: initialState,
  },
  reducers: {
    decrementSet: (state, action: PayloadAction<{ exerciseId: string }>) => {
      const stateClone: IExercisePreviewState = clone(state.value);
      const index = stateClone.data?.assignedExercises.findIndex((ex) => ex._id === action.payload.exerciseId);
      if (index === undefined || index < 0) {
        return;
      }

      const data = stateClone.data?.assignedExercises[index];
      if (data && Number(data?.set) && Number(data?.set) > 1) {
        data.set = '' + (Number(data.set) - 1);
        state.value = stateClone;
        return;
      }

      if (stateClone.data?.assignedExercises) {
        stateClone.data?.assignedExercises.splice(index, 1);
        state.value = stateClone;
        return;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patientExerciseAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(patientExerciseAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { decrementSet } = patientExerciseSlice.actions;

export default patientExerciseSlice.reducer;
