import axios, { AxiosInstance } from 'axios';

import { apiConfig } from '../config/api-config';
import { getAuthStorage } from './../utils/storage-util';
import { IAPIResponse } from '../interfaces';
import { ProgramRequest } from '../interfaces/program';
import { ISeriesRequest, ISeriesUpdateRequest, SeriesResponse } from '../interfaces/series';
import { fileDownload } from '../utils/core-utils';

const seriesService = (httpClient: AxiosInstance) => {
  return {
    createSeries: async (series: ISeriesRequest) => {
      return httpClient
        .post<IAPIResponse<null>>('/series', series)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to add series ', data: null };
        });
    },

    updateSeries: async (series: ProgramRequest) => {
      return httpClient
        .patch<IAPIResponse<null>>('/series', series)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update series ', data: null };
        });
    },

    getSeriesByProgramIdAndWeek: async (programId: string, week: number) => {
      return httpClient
        .get<IAPIResponse<SeriesResponse>>(`/series/${programId}/${week}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update series ', data: null };
        });
    },

    downloadSeriesByProgramIdAndWeek: async (programId: string, week: number): Promise<boolean> => {
      return new Promise((resolve) => {
        const auth = getAuthStorage();
        axios({
          url: `${apiConfig.baseURL}series/${programId}/${week}/download`, //your url
          method: 'GET',
          responseType: 'blob',
          headers: {
            'x-domain-host': window.location.hostname || '',
            Authorization: auth ? `Bearer ${auth.token}` : '',
          },
        })
          .then((res) => {
            fileDownload(res.data).then(resolve);
            return true;
          })
          .catch(() => resolve(false));
      });
    },

    getSeriesByProgramIdAndWeekForPatient: async (programId: string, week: number) => {
      return httpClient
        .get<IAPIResponse<SeriesResponse>>(`/series/patient/${programId}/${week}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update series ', data: null };
        });
    },

    updateSeriesAndAssignedExercise: async (series: ISeriesUpdateRequest) => {
      return httpClient
        .patch<IAPIResponse<null>>('/series/assignedExercise', series)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update series ', data: null };
        });
    },

    deleteSeries: async (id: string) => {
      return httpClient
        .delete<IAPIResponse<null>>(`/series/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Filed to delete series', data: null };
        });
    },
  };
};

export default seriesService;
