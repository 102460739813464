import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth-reducer';
import userReducer from './user-reducer';
import programReducer from './program-reducer';
import seriesExerciseListReducer from './series-exercise-list-reducer';
import seriesExerciseSaveReducer from './series-exercise-save-reducer';
import patientExerciseReducer from './patient-exercises-reducer';
import aestheticsReducer from './aesthetics-reducer';

export const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  programToken: programReducer,
  seriesExerciseList: seriesExerciseListReducer,
  seriesExercisesSave: seriesExerciseSaveReducer,
  patientExercises: patientExerciseReducer,
  aestheticsSettings: aestheticsReducer,
});
