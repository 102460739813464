import { lazy } from 'react';

const ListPatients = lazy(() => import('../pages/specialist/patient/list-patient/ListPatients'));
const AddPatients = lazy(() => import('../pages/specialist/patient/add-patient/AddPatients'));
const AddProgram = lazy(() => import('../pages/specialist/program/add-program/AddProgram'));
const CreateProgram = lazy(() => import('../pages/specialist/program/create-program/CreateProgram'));
const AddSeries = lazy(() => import('../pages/specialist/series/add-series/AddSeries'));
const CreateSeries = lazy(() => import('../pages/specialist/series/create-series/CreateSeries'));
const ListPrograms = lazy(() => import('../pages/specialist/program/list/ListPrograms'));
const ListExercises = lazy(() => import('../pages/specialist/exercise/list/ListExercises'));
const CreateExercises = lazy(() => import('../pages/specialist/exercise/create-exercise/CreateExercise'));
const PatientsUpdates = lazy(() => import('../pages/specialist/patient/updates/PatientUpdates'));
const ChangePassword = lazy(() => import('../pages/settings/ChangePassword'));
const MyAccount = lazy(() => import('../pages/specialist/my-account/MyAccount'));
const PlansTemplate = lazy(() => import('../pages/specialist/plans/Plans'));
const PaymentDetails = lazy(() => import('../pages/specialist/payment/Payment'));
const AddSpecialist = lazy(() => import('../pages/specialist/specialist/AddSpecialist'));
const EditLibraryProgram = lazy(() => import('../pages/specialist/program/edit-library-program/EditLibraryProgram'));
const EditLibrarySeries = lazy(() => import('../pages/specialist/series/library-series/EditLibrarySeries'));
const SupportTicket = lazy(() => import('../pages/specialist/support/SupportTicket'));

export const PractitionerRoutes = [
  {
    path: '/patients',
    component: ListPatients,
  },
  {
    path: '/patient/create',
    component: AddPatients,
  },
  {
    path: '/patient/edit/:id',
    component: AddPatients,
  },
  {
    path: '/patient/:id/program',
    component: ListPrograms,
  },
  {
    path: '/program/add',
    component: AddProgram,
  },
  {
    path: '/programs',
    component: ListPrograms,
  },
  {
    path: '/program/:id/edit-program',
    component: EditLibraryProgram,
  },
  {
    path: '/patient/:id/add-program',
    component: AddProgram,
  },
  {
    path: '/patient/:id/create-program',
    component: CreateProgram,
  },
  {
    path: '/program/:id/series',
    component: AddSeries,
  },
  {
    path: '/program/:id/series/create',
    component: CreateSeries,
  },
  {
    path: '/program/:id/series/:seriesId/:week',
    component: CreateSeries,
  },
  {
    path: '/program/:id/series/add',
    component: EditLibrarySeries,
  },
  {
    path: '/program/:id/series/:seriesId/',
    component: EditLibrarySeries,
  },
  {
    path: '/exercise/create',
    component: CreateExercises,
  },
  {
    path: '/exercise/:id',
    component: CreateExercises,
  },
  {
    path: '/exercise/:id/:assigned',
    component: CreateExercises,
  },
  {
    path: '/exercises',
    component: ListExercises,
  },
  {
    path: '/notifications',
    component: PatientsUpdates,
  },
  {
    path: '/patient/:id/notifications',
    component: PatientsUpdates,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
  {
    path: 'plans/:id/payment-details/:priceListId',
    component: PaymentDetails,
  },
  {
    path: '/my-account/:tabHeader',
    component: MyAccount,
  },
  {
    path: '/plans',
    component: PlansTemplate,
  },
  {
    path: '/specialist/create',
    component: AddSpecialist,
  },
  {
    path: '/support',
    component: SupportTicket,
  },
];
