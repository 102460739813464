import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { SeriesLog } from '../interfaces/log';

const seriesLogService = (httpClient: AxiosInstance) => {
  return {
    createSeriesLog: async (seriesLog: SeriesLog) => {
      return httpClient
        .post<IAPIResponse<null>>('/series-log', seriesLog)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Creating program log failed', data: null };
        });
    },

    getSeriesLog: async (programId: string, week: number) => {
      return httpClient
        .get<IAPIResponse<null>>(`/series-log/${programId}/${week}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Program log failed', data: null };
        });
    },

    getCompletedExercise: async (programId: string, week: number, seriesId: string) => {
      return httpClient
        .get<IAPIResponse<null>>(`/series-log/${programId}/${week}/${seriesId}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Program log failed', data: null };
        });
    },
  };
};
export default seriesLogService;
