const processENV = process.env;
const serverURL = processENV.REACT_APP_API_URL || '/api/';
const basePath = processENV.REACT_APP_BASE_URL || '/';

export const KEY_STRING = 'J8JasqoTF925nqMAjiva';

export const apiConfig = {
  baseURL: serverURL,
  timeout: 30000,
  basePath,
};

export const IMAGE_COUNT = 4;

export const termsAndConditionsUrl = processENV.REACT_APP_TERMS_AND_CONDITIONS_URL || '';
export const privacyPolicyUrl = processENV.REACT_APP_PRIVACY_POLICY_URL || '';
