import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { ISpecialistsData } from '../interfaces/specialist';

const specialistService = (httpClient: AxiosInstance) => {
  return {
    getSpecialistByOrgId: async () => {
      return httpClient
        .get<IAPIResponse<ISpecialistsData[]>>(`/practitioners/list`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to read specialist', data: undefined };
        });
    },
    addSpecialist: async (specialistData: {}) => {
      return httpClient
        .post<IAPIResponse<null>>(`/practitioners/create-practitioner`, specialistData)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Updating specialist details failed', data: null };
        });
    },
    editSpecialist: async (userId: string, specialistData: {}) => {
      return httpClient
        .patch<IAPIResponse<null>>(`/practitioners/${userId}`, specialistData)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Updating specialist details failed', data: null };
        });
    },

    promoteSpecialist: async (id: string) => {
      return httpClient
        .patch<IAPIResponse>(`/practitioners/specialist/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Delete specialist failed', data: null };
        });
    },

    deleteSpecialist: async (id: string) => {
      return httpClient
        .delete<IAPIResponse>(`/practitioners/specialist/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Delete specialist failed', data: null };
        });
    },

    resetPassword: async (id: string, data: {}) => {
      return httpClient
        .post<IAPIResponse>(`/practitioners/specialist/reset-password/${id}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Reset password failed', data: null };
        });
    },

    deleteAccount: async () => {
      return httpClient
        .delete<IAPIResponse>('/practitioners')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Delete account failed.', data: null };
        });
    },
  };
};

export default specialistService;
