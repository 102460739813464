export const validateEmail = (email: string): string | null => {
  const checkEmail = /^.+@.+\..+$/.test(email);
  if (!checkEmail) {
    return 'Invalid email id.';
  }
  return null;
};

export const validatePassword = (password: string): string | null => {
  const checkPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%_^&*.?])[a-zA-Z0-9!@#$%_^&*.?]{8,}$/.test(password);
  if (!checkPassword) {
    return 'To make your password more secure use minimum 8 characters.And must contain at least one number and one uppercase and lowercase letter, one special characters.';
  }
  return null;
};

export const validateMobile = (mobile: string): string | null => {
  const checkMobile = /^\d{6,10}$/gi.test(mobile);
  if (!checkMobile) {
    return 'Minimum six digits required.';
  }
  return null;
};

export const validateCardNumber = (cardNumber: string): string | null => {
  const checkCardNumber = /^\d{16}$/;
  if (!checkCardNumber) {
    return 'Please enter your 16 digit credit card numbers.';
  }
  return null;
};

export const validateCvv = (Cvv: string): string | null => {
  const checkCvv = '^[0-9]{3}$';
  if (!checkCvv) {
    return 'Please enter 3 digit number.';
  }
  return null;
};
