import { IAPIResponse, IAuthToken } from './';

export interface IAuth extends IAPIResponse<IAuthToken> {
  loading?: boolean;
}

export enum Role {
  PRACTITIONER = 'practitioner',
  PATIENT = 'patient',
  ADMIN = 'admin',
  SOLO = 'solo',
  BUSINESS_ADMIN = 'business_admin',
}

export enum Plan {
  SOLO = 'solo',
  FREE = 'free',
  BUSINESS = 'business',
  CUSTOM = 'custom',
}
