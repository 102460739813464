import { AxiosInstance } from 'axios';

import { IAPIResponse } from '../interfaces';
import { IOrganizationInfo } from '../interfaces/organization';

const infoService = (httpClient: AxiosInstance) => {
  return {
    getInfo: async () => {
      return httpClient
        .get<IAPIResponse<IOrganizationInfo>>('/info')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Invalid email or password', data: undefined };
        });
    },
  };
};

export default infoService;
