import { lazy } from 'react';

const PatientDashboard = lazy(() => import('../pages/patient/home/Home'));
const Verification = lazy(() => import('../pages/patient/verification'));
const Series = lazy(() => import('../pages/patient/series/Series'));
const Program = lazy(() => import('../pages/patient/program/Program'));
const Exercise = lazy(() => import('../pages/patient/exercise/exercise'));
const ChangePassword = lazy(() => import('../pages/settings/ChangePassword'));
// const PaymentMessage = lazy(() => import('../pages/settings/PaymentMessage'));


export const PatientRoutes = [
  {
    path: '/home',
    component: PatientDashboard,
  },
  {
    path: '/verification',
    component: Verification,
  },
  {
    path: '/patient-program/:id',
    component: Program,
  },
  {
    path: '/patient-series/:programId/:seriesId/:week',
    component: Series,
  },
  {
    path: '/exercise/:programId/:seriesId/:id/:week',
    component: Exercise,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
  // {
  //   path: '/payment-message',
  //   component: ChangePassword,
  // },
];
