import { FC } from 'react';

import loading from './../../images/loading.gif';
import styles from './app-loader.module.css';

interface AppLoaderProps {}

const AppLoader: FC<AppLoaderProps> = () => {
  return (
    <div className={styles.loader_wrapper}>
      <img alt="loading" src={loading} className={styles.loader_image} />
    </div>
  );
};

export default AppLoader;
