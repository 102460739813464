import { AxiosInstance, AxiosResponse } from 'axios';

import { IAPIResponse, IAuthToken, ISignUpRes, IUserData } from '../interfaces';
import { ILoginAsTokenVerifyResponse, ITokenVerificationResponse, ITokenVerifyForOrg } from '../interfaces/specialist';
import { setAuthStorage } from '../utils/storage-util';

interface AuthService {
  signUp: (data: {}, token: string) => Promise<IAPIResponse<ISignUpRes>>;
  practitionerSignUp: (data: {}, token: string) => Promise<IAPIResponse<ISignUpRes>>;
  login: (email: string, password: string, orgId: string) => Promise<IAPIResponse<IAuthToken>>;
  forgotPassword: (email: string, orgId: string) => Promise<IAPIResponse>;
  resetPassword: (token: string, password: string) => Promise<IAPIResponse>;
  tokenVerification: (token: string) => Promise<IAPIResponse<ITokenVerifyForOrg>>;
  getInfo: (email: string) => Promise<IAPIResponse<IUserData>>;
  verifyCode: (code: string) => Promise<IAPIResponse<ITokenVerificationResponse>>;
  Register: (data: {}) => Promise<IAPIResponse<{ userId: string } | undefined>>;
  patientSignUp: (data: {}) => Promise<IAPIResponse<ISignUpRes>>;
  loginAsToken: (token: string, orgId: string) => Promise<IAPIResponse<ILoginAsTokenVerifyResponse>>;
  generateOtp: (data: {}) => Promise<IAPIResponse>;
  loginAs: (email: string, otp: string, orgId: string, token: string) => Promise<IAPIResponse<IAuthToken>>;
  generateMail: (id: string) => Promise<IAPIResponse>;
}

const authService = (httpClient: AxiosInstance): AuthService => {
  return {
    signUp: async (data: {}, token: string): Promise<IAPIResponse<ISignUpRes>> => {
      try {
        const res = await httpClient.put<IAPIResponse<ISignUpRes>>('/practitioners/sign-up/' + token, data);
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Sign up failed try again.' };
      }
    },

    practitionerSignUp: async (data: {}, token: string): Promise<IAPIResponse<ISignUpRes>> => {
      try {
        const res = await httpClient.put<IAPIResponse<ISignUpRes>>('/practitioners/practitioner-signup/' + token, data);
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Sign up failed try again.' };
      }
    },

    login: async (email: string, password: string, orgId: string): Promise<IAPIResponse<IAuthToken>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAuthToken>> = await httpClient.post('/login', {
          email,
          password,
          orgId,
        });

        if (res.data.data) {
          setAuthStorage(res.data.data);
        }

        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Invalid password.' };
      }
    },

    forgotPassword: async (email: string, orgId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.patch(`/forget-password`, { email, orgId });
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Password reset failed.' };
      }
    },

    resetPassword: async (token: string, password: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.patch(`/reset-password/verify`, {
          token,
          password,
        });
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Password reset failed.' };
      }
    },

    tokenVerification: async (token: string): Promise<IAPIResponse<ITokenVerifyForOrg>> => {
      return httpClient
        .get(`/practitioners/verification/${token}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse<ITokenVerifyForOrg> = { status: false, message: 'Verification failed.' };
          return data;
        });
    },

    getInfo: async (email: string): Promise<IAPIResponse<IUserData>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUserData>> = await httpClient.post('/login', {
          email,
        });

        return res.data;
      } catch (err: any) {
        return { status: false, message: "Email doesn't exist." };
      }
    },

    verifyCode: async (code: string): Promise<IAPIResponse<ITokenVerificationResponse>> => {
      return httpClient
        .post(`/patients/code-verify/`, { otp: code })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse = { status: false, message: 'Code verification failed.' };
          return data;
        });
    },

    Register: async (data: {}): Promise<IAPIResponse<{ userId: string }>> => {
      return httpClient
        .post('/create-organization', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse = { status: false, message: 'Registration failed.' };
          return data;
        });
    },

    patientSignUp: async (data: {}): Promise<IAPIResponse<ISignUpRes>> => {
      try {
        const res: AxiosResponse<IAPIResponse<ISignUpRes>> = await httpClient.post('/patients/signup/', data);
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Sign up failed try again.' };
      }
    },

    loginAsToken: async (token: string, orgId: string): Promise<IAPIResponse<ILoginAsTokenVerifyResponse>> => {
      return httpClient
        .post(`/practitioners/token-verify/${token}`, { orgId })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse = { status: false, message: 'Token either expired or invalid.', data: undefined };
          return data;
        });
    },

    generateOtp: async (data: {}): Promise<IAPIResponse> => {
      return httpClient
        .post(`/practitioners/create-otp`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse = { status: false, message: 'Token either expired or invalid.' };
          return data;
        });
    },

    loginAs: async (email: string, otp: string, orgId: string, token: string): Promise<IAPIResponse<IAuthToken>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAuthToken>> = await httpClient.post('/practitioners/login-as', {
          email,
          otp,
          orgId,
          token,
        });

        if (res.data.data) {
          setAuthStorage(res.data.data);
        }

        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Invalid password.' };
      }
    },

    generateMail: async (id: string): Promise<IAPIResponse> => {
      return httpClient
        .patch(`/organization/resend-mail/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          const data: IAPIResponse = { status: false, message: 'Token either expired or invalid.' };
          return data;
        });
    },
  };
};
export default authService;
