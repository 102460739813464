import { AxiosInstance } from 'axios';

import { IAPIResponse, IPaymentData, IPaymentRes, IPromoRes } from '../interfaces';

const paymentService = (httpClient: AxiosInstance) => {
  return {
    getPaymentsStatus: async () => {
      return httpClient
        .get('/practitioners/transaction')
        .then((res) => res.data)
        .catch(() => {
          return { status: false, message: 'get status failed', data: null };
        });
    },

    payment: async (formData: IPaymentData | { planId: string; priceListId: string }) => {
      try {
        const res = await httpClient.post<IAPIResponse<IPaymentRes>>(`plans/subscription`, formData);
        return res.data;
      } catch (err: any) {
        return { status: false, message: err?.response?.data?.message || 'Payment Failed', data: null };
      }
    },

    getPromoCode: async (code: string) => {
      try {
        const res = await httpClient.post<IAPIResponse<IPromoRes>>(`plans/promo`, { code });
        return res.data;
      } catch (err: any) {
        return { status: false, message: err?.response?.data?.message || 'Discount code not found', data: null };
      }
    },
  };
};

export default paymentService;
