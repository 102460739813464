import { clearSessionStorage } from './../utils/storage-util';
import axios from 'axios';

import { apiConfig } from '../config/api-config';
import { clearStorage, getAuthStorage } from '../utils/storage-util';
import auth from './auth-service';
import exercise from './exercise-service';
import info from './info-service';
import patient from './patient-service';
import program from './program-service';
import series from './series.services';
import notification from './notifications-service';
import programLog from './program-log-service';
import assignedExercise from './assigned-exercise-service';
import seriesLog from './series-log-service';
import settings from './setting-service';
import specialist from './specialist-service';
import plan from './plan-service';
import aesthetics from './settings-services';
import payment from './payment-service';
import user from './user-service';
import library from './library-service';
import support from './support-service';

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['x-domain-host'] = window.location.hostname || '';
  }

  const auth = getAuthStorage();
  if (auth && config.headers) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }

  if (!config.params) {
    config.params = {};
  }

  config.params['cache-invalidate'] = Date.now();
  return config;
});

export const logout = () => {
  clearStorage();
  clearSessionStorage();
  window.location.href = `/login`;
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.message === 'Network Error') {
      return;
    }

    if (err.request.responseURL.includes('/api/login')) {
      return;
    }

    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      logout();
      return;
    }

    if (err.response.status === 403 && err.config && !err.config.__isRetryRequest) {
      logout();
      return;
    }

    throw err;
  },
);

const authService = auth(httpClient);
const infoService = info(httpClient);
const patientService = patient(httpClient);
const programService = program(httpClient);
const seriesService = series(httpClient);
const exerciseService = exercise(httpClient);
const notificationService = notification(httpClient);
const programLogService = programLog(httpClient);
const assignedExerciseService = assignedExercise(httpClient);
const seriesLogService = seriesLog(httpClient);
const settingService = settings(httpClient);
const specialistService = specialist(httpClient);
const planService = plan(httpClient);
const settingsService = aesthetics(httpClient);
const paymentService = payment(httpClient);
const userService = user(httpClient);
const libraryService = library(httpClient);
const supportService = support(httpClient);

export {
  authService,
  infoService,
  patientService,
  programService,
  seriesService,
  exerciseService,
  notificationService,
  programLogService,
  assignedExerciseService,
  seriesLogService,
  settingService,
  specialistService,
  planService,
  settingsService,
  paymentService,
  userService,
  libraryService,
  supportService,
};
