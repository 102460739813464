import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IProgramDataWithLoading } from '../../interfaces/program';
import { programService } from '../../service';

const initialState: { value: IProgramDataWithLoading } = {
  value: { status: false, message: '', loading: false },
};

export const getPatientPrograms = createAsyncThunk(
  'patient/programs',
  async (userId: string): Promise<IProgramDataWithLoading> => {
    try {
      const res = await programService.getPatientPrograms(userId);
      return { ...res, loading: false, data: res.data ? res.data : undefined };
    } catch (err: any) {
      return { status: false, message: err.message as string, loading: false };
    }
  },
);

export const patientProgramsSlice = createSlice({
  name: 'patient-programs-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatientPrograms.pending, (state) => {
      state.value = { ...initialState.value, loading: true };
    });
    builder.addCase(getPatientPrograms.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export default patientProgramsSlice.reducer;
